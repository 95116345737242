import React, {useContext, useEffect, useRef, useState} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"
import {Link} from "gatsby";
import LangContext from "../../context/LangContext";
import asesoriafinanciera from "../../../static/images/lca/asesoriafinanciera.jpg"
import estructuracion from "../../../static/images/lca/estructuracion.jpg"
import valuacion from "../../../static/images/lca/valuacion.jpg"
import reestructura from "../../../static/images/lca/reestructura.jpg"
import gestionfondos from "../../../static/images/lca/gestionfondos.jpg"

const ServiciosPage = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    const {lang} = useContext(LangContext)
    const [data, setData] = useState({})
    if (lang === "es") {
        import ("../../messages/es")
            .then((msg) => {
                setData(msg.default)
            })
    } else {
        import ("../../messages/en")
            .then((msg) => {
                setData(msg.default)
            })
    }
    return (
        <Layout location={props.location}>
            <SEO title="Nuestros servicios | LCA"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.8"
                         style={{backgroundImage: 'url("/images/lca/header3.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">
                                <h1 className="alt-font text-white opacity-6 margin-20px-bottom">
                                    We help solve important financial challenges</h1>
                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
                                    Our services</h2>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="down-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-5 col-md-6">
                                <h5 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    Wide range of financial solutions covering a variety of products</h5>
                                <p className="w-90 lg-w-90">We combine our commitment to excellence with technical
                                    expertise and market intelligence to provide our clients with specialized advisory
                                    services tailored to their needs.</p>
                                <p className="w-90 lg-w-90">Our market insight and technical expertise allow us to use
                                    different asset valuation and cash flow modeling methodologies.</p>
                            </div>
                            <div className="col-12 col-md-6 offset-lg-1 sm-margin-30px-bottom">
                                <img className="" src={"/images/lca/interior/servicios.jpg"} alt={''}/>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end section */}

                {/* start section */}
                <section className="wow animate__fadeIn">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-5 sm-margin-30px-bottom">
                                <h5 className="alt-font font-weight-500 text-extra-dark-gray mb-0">
                                    We structure innovative financing and evaluate the best options for our clients and
                                    their shareholders. We advise from the early stages of projects.
                                </h5>
                            </div>
                            <div className="col-12 col-lg-6 offset-lg-1 col-md-7">
                                <div className="row row-cols-1 row-cols-sm-2">
                                    {/* start counter item */}
                                    <div
                                        className="col counter-style-01 last-paragraph-no-margin xs-margin-30px-bottom">
                                        <h5 className="text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-15px-bottom"
                                        >Relationships <br className="d-none d-md-block"/> &nbsp;</h5>

                                        <p className="w-85 md-w-100">We have close relationships with a diverse range of
                                            equity, debt, and financial guarantees providers,
                                            including multilateral financial institutions and development banks.
                                        </p>
                                    </div>
                                    {/* end counter item */}
                                    {/* start counter item */}
                                    <div className="col counter-style-01 last-paragraph-no-margin">
                                        <h5 className="text-extra-dark-gray alt-font appear font-weight-600 letter-spacing-minus-1px margin-15px-bottom"
                                        >Our clients</h5>

                                        <p className="w-85 md-w-100">Our clients include project sponsors and
                                            developers, investors, shareholders, and government agencies, to whom we
                                            offer advisory services and support in solving
                                            their financing needs.</p>
                                    </div>
                                    {/* end counter item */}
                                </div>
                            </div>
                            <div className="col-12" id="tab-servicios">
                                <div
                                    className="w-100 h-1px bg-medium-gray margin-7-rem-top margin-8-rem-bottom sm-margin-5-rem-tb"/>
                            </div>
                        </div>
                        <div className="row tab-servicios">
                            <div className="col-12 tab-style-01 wow animate__fadeIn" data-wow-delay="0.4s">
                                {/* start tab navigation */}
                                <ul className="nav nav-tabs text-uppercase justify-content-center text-center alt-font font-weight-500 margin-7-rem-bottom lg-margin-5-rem-bottom md-margin-4-rem-bottom sm-margin-20px-bottom">
                                    <li className="nav-item asesoria"><a className="nav-link asesoria active"
                                                                         data-toggle="tab"
                                                                         href="#asesoria-tab">FINANCIAL ADVISOR AND
                                        STRUCTURING AGENT</a>
                                    </li>
                                    <li className="nav-item valuacion"><a className="valuacion nav-link"
                                                                          data-toggle="tab"
                                                                          href="#valuacion">VALUATION AND INDEPENDENT
                                        ANALYSIS</a></li>
                                    <li className="nav-item situaciones"><a className="situaciones nav-link"
                                                                            data-toggle="tab"
                                                                            href="#situaciones">RESTRUCTURING AND
                                        SPECIAL TRANSACTIONS</a></li>
                                    <li className="nav-item gestion-tab"><a className="gestion-tab nav-link"
                                                                            data-toggle="tab"
                                                                            href="#gestion-tab">FUND MANAGEMENT</a>
                                    </li>
                                </ul>
                                {/* end tab navigation */}
                                <div className="tab-content">
                                    {/* start tab item */}
                                    <div id="asesoria-tab" className="asesoria_content tab-pane fade in active show">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-5 text-right sm-margin-40px-bottom">
                                                <img src={asesoriafinanciera}
                                                     className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-6 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Financial Advisory and Structuring</h5>
                                                <p className="w-85 lg-w-100">Latam Capital Advisors excels for its
                                                    extensive experience in developing innovative financial transactions
                                                    for public and private sector infrastructure investors.
                                                </p>
                                                <p>We offer specialized financial advisory, structuring, and assistance
                                                    for the evaluation, decision, negotiation, and execution of:
                                                </p>
                                                <ul className="list3">
                                                    <li>Financing in the capital markets, commercial and development
                                                        banking
                                                    </li>
                                                    <li>Subordinated or mezzanine debt</li>
                                                    <li>Issuance of capital instruments (private equity, public
                                                        offerings or similar instruments, e.g. including infrastructure
                                                        and energy REITs)
                                                    </li>
                                                    <li>Mergers & acquisitions (M&A)</li>
                                                    <li>Financial guarantees</li>
                                                    <li>Restructuring and refinancing</li>
                                                    <li>Feasibility assessment of new projects</li>
                                                    <li>Bid submissions and unsolicited proposals</li>
                                                    <li>Optimal capital structure</li>
                                                </ul>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">Contact
                                                    us</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="valuacion" className="valuacion_content tab-pane fade">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-5 text-right sm-margin-40px-bottom">
                                                <img src={valuacion} className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-6 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Valuation and independent analysis</h5>
                                                <p className="w-85 lg-w-100">
                                                    Valuation is an essential step in investment processes, financings,
                                                    restructurings, and mergers & acquisitions.
                                                </p>
                                                <p>At LCA, we develop proprietary models specifically for each project
                                                    or transaction to be analyzed, employing the most advanced knowledge
                                                    of financial, statistical, probability, and econometric analysis.
                                                </p>
                                                <p>We offer our expert project valuation services to governments and
                                                    private sector investors for decision making based on high-quality
                                                    information and analysis.
                                                </p>
                                                <p>We also provide the required fairness opinions for acquisitions or
                                                    mergers.
                                                </p>
                                                <Link to="/es/contacto"
                                                      className="btn btn-medium btn-dark-gray margin-15px-top">Contact
                                                    us</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="situaciones" className="situaciones_content tab-pane fade">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-5 text-right sm-margin-40px-bottom">
                                                <img src={reestructura}
                                                     className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-6 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Restructuring and special situations</h5>
                                                <p className="w-85 lg-w-100">We develop for to our clients clear and
                                                    viable solutions to complex situations, focusing
                                                    on our clients' interests and their shareholders.
                                                </p>
                                                <p>We draw on our extensive experience to advise on restructuring,
                                                    renegotiations, refinancings, or dispute resolutions.
                                                </p>
                                                <p>We also offer our services to private sector players and governments
                                                    in designing financially viable PPPs, innovative
                                                    financings, securitizations, and securing financial guarantees or
                                                    credit enhancements from development banks, multilateral agencies,
                                                    and export & investment promoters, among other transactions.
                                                </p>
                                                <Link to="/es/contacto"
                                                      className="btn btn-medium btn-dark-gray margin-15px-top">Contact
                                                    us</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="gestion-tab" className="gestion-tab_content tab-pane fade in">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-5 text-right sm-margin-40px-bottom">
                                                <img src={gestionfondos}
                                                     className="w-100"
                                                     alt={''}/>
                                            </div>
                                            <div className="col-12 col-lg-6 offset-lg-1 col-md-6">

                                                <h5 className="alt-font font-weight-500 text-extra-dark-gray">
                                                    Fund management</h5>
                                                <p className="w-85 lg-w-100">We advise fund managers on different
                                                    aspects of the investment process, including acquisitions,
                                                    monitoring, restructurings, financings, valuations,
                                                    independent analysis, and divestitures.
                                                </p>
                                                <p>Since 2020, through Latam Capital Multifondos, we manage the
                                                    private equity fund "Latam Capital Fondo I", which is focused on
                                                    identifying assets, companies, and projects to:
                                                </p>

                                                <ul className="list3">
                                                    <li>Help develop and accelerate the growth of high-quality assets,
                                                        working with selected promoters and developers in strategic
                                                        sectors within the region.
                                                    </li>
                                                    <li>Generate value through optimizing their financial strategy,
                                                        capital structure, and by implementing best management and
                                                        corporate governance practices.
                                                    </li>
                                                    <li>Generate attractive risk-adjusted returns.</li>
                                                </ul>
                                                <a href="/es/contacto"
                                                   className="btn btn-medium btn-dark-gray margin-15px-top">WOULD YOU
                                                    LIKE TO INVEST WITH US OR SUBMIT YOUR PROJECT FOR REVIEW?
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end section */}
            </>
        </Layout>
    )
}

export default ServiciosPage
